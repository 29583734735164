<template>
  <div>
    <v-tabs
      background-color="color2Text"
      slider-color="color3"
    >
      <v-tab
        v-for="(tab, i) in tabs"
        :key="i"
        exact
        :to="tab.to"
        class="color2--text"
      >{{tab.text}}</v-tab>
    </v-tabs>
    <router-view :program="program"></router-view>

  </div>
</template>

<script>
import * as mutations from '@/store/MutationTypes'

export default {
  props: ['username'],
  data () {
    return {
      tabs: [
        { text: 'Member List', to: { name: 'organization-members', params: { username: this.username } } }
        /* {text: 'Member Levels', to: {name: 'organization-members-levels', params: {username: this.username}}}, */
        /* { text: 'Member Invites', to: { name: 'organization-members-invites', params: { username: this.username } } } */
      ],
      program: null
    }
  },
  methods: {
    loadProgram () {
      this.$store.commit(mutations.LOADING, 'membership')
      this.$VBL.get.membership(this.username)
        .then(r => {
          this.program = r.data
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => {
          this.$store.commit(mutations.LOADING_COMPLETE, 'membership')
        })
    }
  },
  created () {
    this.loadProgram()
  }
}
</script>

<style>

</style>
